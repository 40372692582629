import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AppContext } from "../context/AppContext";

const ProtectedRoute: React.FC<any> = ({ element, ...rest }) => {
  const { user } = useContext(AppContext);
  const jwt = localStorage.getItem("jwt");

  const isAuthenticated = !!user && !!jwt;

  return isAuthenticated ? <Outlet /> : <Navigate to="/" replace />;
};

export default ProtectedRoute;
