import {
  Text,
  Flex,
  Icon,
  Link,
  Menu,
  MenuButton,
  Tooltip,
} from "@chakra-ui/react";
import { useContext } from "react";
import { IconType } from "react-icons";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
interface NavItemProps {
  title: string;
  icon: IconType;
  active: boolean;
  href?: string;
  onClick?: () => void;
}

const NavItem = ({ title, icon, active, href, onClick }: NavItemProps) => {
  const { isSidebarOpen } = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <Tooltip
      label={!isSidebarOpen && title}
      bg="teal.600"
      color="white"
      placement="right"
    >
      <Flex
        mt={30}
        flexDir={"column"}
        w={"100%"}
        alignItems={!isSidebarOpen ? "center" : "flex-start"}
      >
        <Menu placement="right">
          <Link
            backgroundColor={active ? "teal.500" : "unset"}
            p={3}
            borderRadius={8}
            w={isSidebarOpen ? "100%" : "unset"}
            _hover={{
              textDecoration: "none",
              backgroundColor: "teal.500",
              "& svg": {
                color: "white",
              },
              "& p": {
                color: "white",
              },
            }}
            onClick={(event) => {
              event.preventDefault();
              onClick && onClick();
              href && navigate(href);
            }}
          >
            <MenuButton w="100%">
              <Flex>
                <Icon
                  as={icon}
                  fontSize={"xl"}
                  color={active ? "white" : "gray.300"}
                />
                <Text
                  ml={5}
                  display={!isSidebarOpen ? "none" : "flex"}
                  color={active ? "white" : "gray.300"}
                >
                  {title}
                </Text>
              </Flex>
            </MenuButton>
          </Link>
        </Menu>
      </Flex>
    </Tooltip>
  );
};

export default NavItem;
