import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import PageNotFound from "./pages/PageNotFound";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./lib/theme";
import i18n from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import hr from "./locales/hr.json";
import eng from "./locales/eng.json";
import JobsPage from "./pages/JobsPage";
import { AppContextProvider } from "./context/AppContext";
import UsersPage from "./pages/UsersPage";
import NewJobPage from "./pages/NewJobPage";
import JobDetailPage from "./pages/JobDetailPage";
import UserDetailPage from "./pages/UserDetailPage";
import AdminRoute from "./lib/AdminRoute";
import Unauthorized from "./pages/Unauthorized";
import AlertMessage from "./components/common/AlertMessages/AlertMessage";
import ProtectedRoute from "./lib/ProtectedRoute";

i18n.use(initReactI18next).init({
  resources: {
    hr: { translation: hr },
    eng: { translation: eng },
  },
  lng: "hr",
  fallbackLng: "hr",
  interpolation: { escapeValue: false },
});

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <ChakraProvider theme={theme}>
        <AppContextProvider>
          <BrowserRouter>
            <AlertMessage />
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/jobs" element={<ProtectedRoute />}>
                <Route path="/jobs" element={<JobsPage />} />
              </Route>
              <Route path="/jobs/new" element={<AdminRoute />}>
                <Route path="/jobs/new" element={<NewJobPage />} />
              </Route>
              <Route path="/users" element={<AdminRoute />}>
                <Route path="/users" element={<UsersPage />} />
              </Route>
              <Route path="/users/new" element={<AdminRoute />}>
                <Route path="/users/new" element={<UserDetailPage />} />
              </Route>
              <Route path="/users/detail/:id" element={<AdminRoute />}>
                <Route path="/users/detail/:id" element={<UserDetailPage />} />
              </Route>
              <Route path="/jobs/detail/:id" element={<ProtectedRoute />}>
                <Route path="/jobs/detail/:id" element={<JobDetailPage />} />
              </Route>
              <Route path="/profile" element={<ProtectedRoute />}>
                <Route path="/profile" element={<UserDetailPage />} />
              </Route>
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
        </AppContextProvider>
      </ChakraProvider>
    </I18nextProvider>
  );
}

export default App;
