import React, { useState, useContext } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Textarea,
  Card,
  CardBody,
  Avatar,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Comment } from "../../../types/comment";
import { AppContext } from "../../../context/AppContext";
import { MdDeleteForever, MdEdit, MdSend } from "react-icons/md";
import { BiDotsVerticalRounded } from "react-icons/bi";

interface CommentSectionProps {
  comments: Comment[];
  handleAddComment: (commentText: string) => void;
  handleDeleteComment: (index: number) => void;
  handleEditComment: (index: number) => void;
  isDisabled?: boolean;
}

const CommentSection = ({
  comments,
  handleAddComment,
  handleDeleteComment,
  handleEditComment,
  isDisabled = false,
}: CommentSectionProps) => {
  const [commentText, setCommentText] = useState("");
  const { t } = useTranslation();
  const { user } = useContext(AppContext);
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const handleCommentInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCommentText(event.target.value);
  };

  function handleButtonClick(): void {
    if (isDisabled || !commentText) return;
    handleAddComment(commentText);
    setCommentText("");
  }

  return (
    <>
      <FormLabel color="gray.300">{t("job.comments")}</FormLabel>
      <Card w={isMobile ? "100%" : "650px"}>
        <CardBody>
          <Box mt={4}>
            {comments.map((comment, index) => (
              <Flex
                mt={4}
                align={"flex-start"}
                mb={3}
                key={comment.id || comment.text}
              >
                <Avatar
                  size={"sm"}
                  src={
                    comment.id
                      ? `${comment?.author?.filePath}`
                      : `${user?.filePath}`
                  }
                />
                <Flex flexDir={"column"} ml={4} flexGrow={1}>
                  <Heading as={"h3"} size={"sm"}>
                    {comment.id
                      ? `${comment?.author?.firstName} ${comment?.author?.lastName}`
                      : `${user?.firstName} ${user?.lastName}`}
                  </Heading>
                  <Text color={"gray.300"} wordBreak={"break-all"}>
                    {comment.text.toString()}
                  </Text>
                </Flex>
                {(!comment.id || comment.authorId === user?.id) && (
                  <Menu>
                    <MenuButton
                      isDisabled={isDisabled}
                      as={IconButton}
                      aria-label="Options"
                      icon={<BiDotsVerticalRounded />}
                      variant="outline"
                    />
                    <MenuList boxShadow={"md"}>
                      <MenuItem
                        onClick={() => {
                          setCommentText(comment.text);
                          handleEditComment(index);
                        }}
                        icon={<MdEdit fontSize={"20px"} color="teal" />}
                      >
                        {t("job.editComment").toString()}
                      </MenuItem>
                      <MenuItem
                        icon={<MdDeleteForever fontSize={"20px"} color="red" />}
                        onClick={() => handleDeleteComment(index)}
                      >
                        {t("job.deleteComment").toString()}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                )}
              </Flex>
            ))}
            <FormControl>
              <Flex alignItems={"center"}>
                <Textarea
                  value={commentText}
                  onChange={handleCommentInputChange}
                  placeholder={t("job.addComment").toString()}
                  color="gray.300"
                  backgroundColor="white"
                  boxShadow="base"
                  _placeholder={{ color: "gray.300" }}
                  isDisabled={isDisabled}
                />
                <Button
                  as={IconButton}
                  onClick={() => handleButtonClick()}
                  ml={2}
                  icon={<MdSend color="teal" size={"20px"} />}
                  size={"bg"}
                  isDisabled={isDisabled}
                />
              </Flex>
            </FormControl>
          </Box>
        </CardBody>
      </Card>
    </>
  );
};

export default CommentSection;
