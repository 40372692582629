export enum ServerResponse {
  Success = 200,
  Created = 400,
  Unauthorized = 401,
  NotFound = 404,
  ServerError = 500,
  Conflict = 409,
}

export enum AlertStatus {
  Error = "error",
  Success = "success",
  Loading = "loading",
}
