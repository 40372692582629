import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import TextInput from "../components/common/TextInput/TextInput";
import { userTypeMapper } from "../lib/mappers";
import { isValidPassword } from "../lib/utils";
import {
  createUser,
  deleteUserById,
  getUserById,
  updateUser,
} from "../services/user";
import { UserType } from "../types/user";
import { AppContext } from "../context/AppContext";
import { AlertStatus, ServerResponse } from "../types/ServerResponse";
import { parseError } from "../lib/errorParser";
import AlertDialog from "../components/common/AlertDialog/AlertDialog";

enum PageType {
  NewUser = 1,
  EditUser = 2,
  UserProfile = 3,
}

const UserDetailPage = () => {
  const [pageType, setPageType] = useState<PageType>(PageType.NewUser);
  const [firstName, setFirstName] = useState<string>("");
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>("");
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [usernameError, setUsernameError] = useState<boolean>(false);
  const [userType, setUserType] = useState<number>(1);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [fileUrl, setFileUrl] = useState<string>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false);
  const {
    user: currentUser,
    setUser,
    setAlertMessage,
  } = useContext(AppContext);
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const url = window.location.href;

  useEffect(() => {
    if (id) {
      setPageType(PageType.EditUser);
    } else if (url.includes("profile")) {
      setPageType(PageType.UserProfile);
    } else {
      setPageType(PageType.NewUser);
    }
  }, [url, id]);

  useEffect(() => {
    async function fetchUser() {
      try {
        if (pageType === PageType.EditUser) {
          const user = await getUserById(Number(id));
          return user;
        } else {
          const user = await getUserById(Number(currentUser?.id));
          return user;
        }
      } catch (error) {
        console.log(error);
        if (error instanceof Error) {
          const errorData = parseError(error);
          if (errorData?.status === ServerResponse.NotFound) {
            setIsLoading(false);
            return navigate("/not-found");
          }
        }
        setIsLoading(false);
        setAlertMessage(t("errors.serverError").toString(), AlertStatus.Error);
      }
    }

    async function fetchData() {
      if (pageType === PageType.EditUser || pageType === PageType.UserProfile) {
        const user = await fetchUser();
        setFirstName(user?.firstName || "");
        setLastName(user?.lastName || "");
        setUsername(user?.username || "");
        setUserType(user?.type || UserType.Admin);

        // Only set the fileUrl state if there is no existing image data for the user
        if (user?.filePath) {
          setFileUrl(user?.filePath);
        }
      }
    }

    fetchData();
  }, [pageType]);

  const getPageTitle = () => {
    switch (pageType) {
      case PageType.NewUser:
        return t("user.newUser");
      case PageType.EditUser:
        return t("user.editUser");
      case PageType.UserProfile:
        return t("sidebar.myProfile");
    }
  };

  const getButtonLabel = () => {
    switch (pageType) {
      case PageType.NewUser:
        return t("user.createUser");
      case PageType.EditUser:
        return t("common.saveChanges");
      case PageType.UserProfile:
        return t("common.saveChanges");
    }
  };

  function handleFirstNameChange(event: ChangeEvent<HTMLInputElement>): void {
    if (event.target.value) setFirstNameError(false);
    setFirstName(event.target.value);
  }

  function handleLastNameChange(event: ChangeEvent<HTMLInputElement>): void {
    if (event.target.value) setLastNameError(false);
    setLastName(event.target.value);
  }

  function handleUsernameChange(event: ChangeEvent<HTMLInputElement>): void {
    if (event.target.value) setUsernameError(false);
    setUsername(event.target.value);
  }

  function handleTypeChange(event: ChangeEvent<HTMLSelectElement>): void {
    setUserType(Number(event.target.value));
  }

  function handlePasswordChange(event: ChangeEvent<HTMLInputElement>): void {
    if (event.target.value || isValidPassword(event.target.value))
      setPasswordError(false);
    setPassword(event.target.value);
    setPasswordError(!event.target.value);
  }

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleAddPhoto = () => {
    if (fileInputRef?.current) {
      fileInputRef?.current.click();
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const newFiles: File[] = Array.from(files);
      setSelectedFile(newFiles[0]);
      setFileUrl(URL.createObjectURL(files[0]));
    }
  };

  const handleNewUser = async (): Promise<void> => {
    if (!firstName) {
      setFirstNameError(true);
      return;
    }
    if (!lastName) {
      setLastNameError(true);
      return;
    }
    if (!username) {
      setUsernameError(true);
      return;
    }
    if (
      pageType === PageType.NewUser &&
      (!password || !isValidPassword(password))
    ) {
      setPasswordError(true);
      return;
    }

    if (
      ((pageType === PageType.EditUser && password) ||
        (pageType === PageType.UserProfile && password)) &&
      !isValidPassword(password)
    ) {
      setPasswordError(true);
      return;
    }

    try {
      setIsLoading(true);
      const formData = new FormData();

      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("type", userType.toString());
      if (password) {
        formData.append("password", password);
      }
      formData.append("username", username);
      if (selectedFile) {
        formData.append("files", selectedFile);
      }
      if (pageType === PageType.NewUser) {
        await createUser(formData);
        setAlertMessage(
          t("successResponse.userCreate").toString(),
          AlertStatus.Success
        );
        setIsLoading(false);
      } else {
        const updatedUser = await updateUser(
          formData,
          id ? Number(id) : currentUser?.id || 0
        );
        setAlertMessage(
          t("successResponse.userUpdate").toString(),
          AlertStatus.Success
        );
        setIsLoading(false);
        if (
          pageType === PageType.UserProfile ||
          (pageType === PageType.EditUser && currentUser?.id === Number(id))
        ) {
          setUser(updatedUser);
        }
      }
      if (currentUser?.type === UserType.Admin) {
        navigate("/users");
      } else {
        navigate("/jobs");
      }
    } catch (error) {
      if (error instanceof Error) {
        const errorData = parseError(error);
        if (errorData?.status === ServerResponse.Conflict) {
          setIsLoading(false);
          return setAlertMessage(t("errors.errorConflict").toString());
        } else {
          setIsLoading(false);
          return setAlertMessage(t("errors.serverError").toString());
        }
      }
      setAlertMessage(t("errors.serverError").toString(), AlertStatus.Error);
      setIsLoading(false);
    }
  };

  const handleDeleteUser = async (): Promise<void> => {
    try {
      setShowAlertDialog(false);
      if (!id || currentUser?.type !== UserType.Admin) return;
      setIsLoading(true);
      await deleteUserById(Number(id));
      setAlertMessage(
        t("successResponse.userDelete").toString(),
        AlertStatus.Success
      );
      setIsLoading(false);
      navigate("/users");
    } catch (error) {
      setAlertMessage(t("errors.serverError").toString(), AlertStatus.Error);
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <Flex
        flexDir={"column"}
        m={isMobile ? 0 : "2.5vh"}
        p={isMobile ? "2.5vh" : 0}
        w={"100%"}
        h={"100%"}
      >
        <Heading size="lg" mb={"2.5vh"} color={"teal.600"} my={5}>
          {getPageTitle()}
        </Heading>
        <Flex w={isMobile ? "100%" : "650px"} justifyContent={"center"} mb={5}>
          <Box boxShadow={"lg"} onClick={handleAddPhoto}>
            <Avatar
              size={"2xl"}
              cursor={"pointer"}
              m={3}
              src={fileUrl && fileUrl}
            />
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileInputChange}
              disabled={isLoading}
            />
          </Box>
        </Flex>

        <TextInput
          isInvalid={!!firstNameError}
          errorMessage={t("user.firstNameError").toString()}
          formLabel={t("user.firstName")}
          onChange={handleFirstNameChange}
          value={firstName}
          placeholder={t("user.firstName").toString()}
          showError={!!firstNameError}
          disabled={isLoading}
        />

        <TextInput
          isInvalid={!!lastNameError}
          errorMessage={t("user.lastNameError").toString()}
          formLabel={t("user.lastName")}
          onChange={handleLastNameChange}
          value={lastName}
          placeholder={t("user.lastName").toString()}
          showError={!!lastNameError}
          disabled={isLoading}
        />

        <TextInput
          isInvalid={!!usernameError}
          errorMessage={t("user.usernameError").toString()}
          formLabel={t("user.username")}
          onChange={handleUsernameChange}
          value={username}
          placeholder={t("user.username").toString()}
          showError={!!usernameError}
          disabled={currentUser?.type !== UserType.Admin || isLoading}
        />

        <FormControl
          mb={5}
          isInvalid={passwordError}
          w={isMobile ? "100%" : "650px"}
        >
          <FormLabel color={"gray.300"}>{t("common.password")}</FormLabel>
          <InputGroup size="md" boxShadow={"base"}>
            <Input
              backgroundColor={"white"}
              value={password}
              pr="4.5rem"
              type={showPassword ? "text" : "password"}
              placeholder={t("login.passwordPlaceholder").toString()}
              onChange={handlePasswordChange}
              _placeholder={{ color: "gray.300" }}
              disabled={isLoading}
            />
            {password && (
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={handleClickShowPassword}
                  isDisabled={isLoading}
                >
                  {showPassword
                    ? t("login.hidePassword")
                    : t("login.showPassword")}
                </Button>
              </InputRightElement>
            )}
          </InputGroup>
          {passwordError && (
            <FormErrorMessage>
              {!password
                ? t("login.passwordError")
                : t("login.invalidPassword")}
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl w={isMobile ? "100%" : "650px"} mb={5}>
          <FormLabel color={"gray.300"}>{t("user.type")}</FormLabel>
          <Select
            boxShadow={"base"}
            color={"gray.300"}
            backgroundColor={"white"}
            onChange={handleTypeChange}
            value={userType}
            _placeholder={{ color: "gray.300" }}
            disabled={currentUser?.type !== UserType.Admin || isLoading}
          >
            {[1, 2, 3].map((option) => {
              return (
                <option key={option} value={option}>
                  {userTypeMapper(option, t)}
                </option>
              );
            })}
          </Select>
        </FormControl>
        <ButtonGroup size="md" isAttached mt={4}>
          <Button
            isLoading={isLoading}
            onClick={handleNewUser}
            colorScheme="teal"
          >
            {getButtonLabel()}
          </Button>
          {currentUser?.type === UserType.Admin &&
            pageType === PageType.EditUser &&
            id &&
            currentUser?.id !== Number(id) && (
              <Button
                isLoading={isLoading}
                onClick={() => setShowAlertDialog(true)}
                mx={4}
                colorScheme="red"
              >
                {t("user.deleteUser")}
              </Button>
            )}
        </ButtonGroup>
        {currentUser?.type === UserType.Admin &&
          pageType === PageType.EditUser &&
          id &&
          currentUser?.id !== Number(id) &&
          showAlertDialog && (
            <AlertDialog
              title={t("user.userDeletion")}
              description={t("user.deleteUserAreYouSure")}
              acceptButtonText={t("user.deleteUser")}
              cancelButtonText={t("user.cancel")}
              onCancel={() => setShowAlertDialog(false)}
              isOpen={showAlertDialog}
              onSuccess={handleDeleteUser}
            />
          )}
      </Flex>
    </Layout>
  );
};

export default UserDetailPage;
