import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { UserType } from "../types/user";

const AdminRoute: React.FC = () => {
  const { user } = useContext(AppContext);

  const isAdmin = user?.type === UserType.Admin;

  return isAdmin ? <Outlet /> : <Navigate to={user ? "/unauthorized" : "/"} />;
};

export default AdminRoute;
