import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";

interface TextInputProps {
  isInvalid?: boolean;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  showError?: boolean;
  errorMessage?: string;
  formLabel: string;
  type?: string;
  disabled?: boolean;
}

const TextInput = ({
  isInvalid = false,
  value,
  placeholder,
  showError = false,
  errorMessage = "",
  formLabel,
  onChange,
  type = "text",
  disabled = false,
}: TextInputProps) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  return (
    <FormControl
      mb={5}
      isInvalid={isInvalid}
      w={isMobile ? "100%" : "650px"}
      color={"gray.300"}
    >
      <FormLabel>{formLabel}</FormLabel>
      <Input
        color={"gray.300"}
        type={type}
        backgroundColor={"white"}
        value={value}
        onChange={(event) => onChange(event)}
        placeholder={placeholder}
        boxShadow={"base"}
        _placeholder={{ color: "gray.300" }}
        disabled={disabled}
      />
      {showError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

export default TextInput;
