import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { UsersTableRows } from "../types/user";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Flex,
  Heading,
  IconButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import Pagination from "../components/Pagination";
import Table from "../components/Table";
import { BsPlus } from "react-icons/bs";
import { getUsers } from "../services/user";
import TableMobile from "../components/TableMobile";

const UsersPage = () => {
  const [userRows, setUsersRows] = useState<UsersTableRows[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const isMobile = useBreakpointValue({ base: true, lg: false });

  useEffect(() => {
    async function fetchUsers() {
      const data = await getUsers(page);
      setIsLastPage(data.isLastPage);
      setUsersRows(data.users);
    }
    fetchUsers();
  }, [page]);

  const handleNewUserButton = () => {
    navigate("/users/new");
  };

  const handleRowClick = (rowId: number) => {
    navigate(`/users/detail/${rowId}`);
  };

  const onPrevClick = () => {
    if (page > 1) setPage(page - 1);
  };

  const onNextClick = () => {
    if (!isLastPage) setPage(page + 1);
  };

  return (
    <Layout>
      <Flex
        flexDir={"column"}
        m={isMobile ? 0 : "2.5vh"}
        p={isMobile ? "2.5vh" : 0}
        w={"100%"}
        h={"100%"}
      >
        <Flex
          w={"100%"}
          flexDir={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          my={5}
        >
          <Heading size="lg" color={"teal.600"}>
            {t("sidebar.users")}
          </Heading>
          <ButtonGroup size="md" isAttached colorScheme="teal">
            <Button onClick={handleNewUserButton}>{t("user.newUser")}</Button>
            <IconButton
              aria-label="Add new user"
              icon={<BsPlus />}
              onClick={handleNewUserButton}
            />
          </ButtonGroup>
        </Flex>

        {isMobile ? (
          <TableMobile
            rows={userRows}
            type="user"
            onRowClick={handleRowClick}
          />
        ) : (
          <Table rows={userRows} type="user" onRowClick={handleRowClick} />
        )}
        <Pagination
          isFirstPage={true}
          isLastPage={true}
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
        />
      </Flex>
    </Layout>
  );
};

export default UsersPage;
