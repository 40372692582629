import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  SimpleGrid,
  Button,
  ButtonGroup,
  Card,
  List,
  ListIcon,
  ListItem,
  Text,
  useBreakpointValue,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Select as SelectMultiple } from "chakra-react-select";
import Layout from "../components/Layout";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useState, useEffect, useContext } from "react";
import { jobStatusMapper } from "../lib/mappers";
import { JobFile, JobStatus } from "../types/job";
import { UserType } from "../types/user";
import { multipleSelectStyles } from "../lib/theme";
import { getUsersByType } from "../services/user";
import { useRef } from "react";
import { createJob } from "../services/job";
import ModalSlider from "../components/common/ModalSlider/ModalSlider";
import TextInput from "../components/common/TextInput/TextInput";
import { MdOutlineClose } from "react-icons/md";
import CommentSection from "../components/common/CommentSection/CommentSection";
import { Comment } from "../types/comment";
import { useNavigate } from "react-router-dom";
import JobNotification from "../components/JobNotification";
import {
  filterImages,
  areValidExtensions,
  isValidUploadSize,
  getJobStatusOptionsList,
} from "../lib/utils";
import { AlertStatus } from "../types/ServerResponse";
import { AppContext } from "../context/AppContext";

type Option = {
  value: string;
  label: string;
};

const NewJobPage = () => {
  const [jobName, setJobName] = useState("");
  const [cadastralMunicipality, setCadastralMunicipality] =
    useState<string>("");
  const [cadastralNumber, setCadastralNumber] = useState<string>("");
  const [status, setStatus] = useState(JobStatus.Unassigned);
  const [jobError, setJobError] = useState(false);
  const [extensionError, setExtensionError] = useState(false);
  const [userOptions, setUserOptions] = useState<Option[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<Option[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileUrls, setFileUrls] = useState<JobFile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [comments, setComments] = useState<Comment[]>([]);
  const [isNotificationSwitched, setIsNotificationSwitched] =
    useState<boolean>(false);
  const [notification, setNotification] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updatedCommentIndex, setUpdatedCommentIndex] = useState<number | null>(
    null
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { setAlertMessage } = useContext(AppContext);

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setJobName(event.target.value);
    if (event.target.value) setJobError(false);
  };

  useEffect(() => {
    return () => {
      setIsModalOpen(false);
    };
  }, []);

  const handleStatusChange = async (
    event: ChangeEvent<HTMLSelectElement>
  ): Promise<void> => {
    setSelectedUsers([]);
    if (
      Number(event.target.value) === JobStatus.FieldWorkInProgress ||
      Number(event.target.value) === JobStatus.OfficeWorkInProgress
    ) {
      const users = await getUsersByType(
        Number(event.target.value) === JobStatus.FieldWorkInProgress
          ? UserType.FieldWorker
          : UserType.OfficeWorker
      );
      const newUserOptions: Option[] = users.map((user) => {
        return {
          value: user.id.toString(),
          label: `${user.firstName} ${user.lastName}`,
        };
      });

      setUserOptions(newUserOptions);
    }
    setStatus(Number(event.target.value));
  };

  const handleAddFile = () => {
    if (fileInputRef?.current) {
      fileInputRef?.current.click();
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setExtensionError(false);
    const files = event.target.files;
    if (files && files.length > 0) {
      const newFiles: File[] = Array.from(files);
      const validExtensions = areValidExtensions(newFiles);
      if (!validExtensions) {
        setExtensionError(true);
        return;
      }

      const newFileUrls: JobFile[] = newFiles
        .filter((file) => file?.type?.includes("image/"))
        .map((file) => {
          return {
            filePath: URL.createObjectURL(file),
            name: file.name,
            type: file.type,
          };
        });

      setSelectedFiles([...selectedFiles, ...newFiles]);
      setFileUrls([...fileUrls, ...newFileUrls]);
    }
  };

  const handleAddComment = (commentText: string) => {
    if (updatedCommentIndex !== null) {
      const newComments = [...comments];
      newComments[updatedCommentIndex] = {
        ...newComments[updatedCommentIndex],
        text: commentText,
      };
      setComments(newComments);
      setUpdatedCommentIndex(null);
      return;
    }
    if (commentText.trim() !== "") {
      setComments((prevComments) => [...prevComments, { text: commentText }]);
    }
  };

  const handleRemoveFile = (index: number) => {
    if (isLoading) return;
    const updatedSelectedFiles = [...selectedFiles];
    updatedSelectedFiles.splice(index, 1);

    const newFileUrls: JobFile[] = updatedSelectedFiles
      .filter((file) => file.type.includes("image/"))
      .map((file) => {
        return {
          filePath: URL.createObjectURL(file),
          name: file.name,
          type: file.type,
        };
      });

    setFileUrls(newFileUrls);
    setSelectedFiles(updatedSelectedFiles);
  };

  const handleNewJobButton = async (): Promise<void> => {
    if (!jobName || !jobName.trim()) {
      setJobError(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    if (!isValidUploadSize(selectedFiles)) {
      setAlertMessage(
        t("errors.filesTooBigError").toString(),
        AlertStatus.Error
      );
      return;
    }

    try {
      setIsLoading(true);
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("files", file);
      });
      formData.append("title", jobName.trim());
      formData.append("cadastralMunicipality", cadastralMunicipality);
      formData.append("cadastralNumber", cadastralNumber);
      formData.append("notification", notification);
      formData.append("status", status.toString());
      comments.forEach((comment) => {
        formData.append("comments", comment.text);
      });
      selectedUsers.forEach((user) => {
        formData.append("selectedUsers", user.value);
      });

      await createJob(formData);
      setIsLoading(false);
      setAlertMessage(
        t("successResponse.jobCreate").toString(),
        AlertStatus.Success
      );
      navigate("/jobs");
    } catch (error) {
      setIsLoading(false);
      setAlertMessage(t("errors.serverError").toString(), AlertStatus.Error);
    }
  };

  const openModal = (index: number) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  function handleEditComment(index: number): void {
    setUpdatedCommentIndex(index);
  }

  function handleDeleteComment(index: number): void {
    const newComments = comments.filter(
      (c, commentIndex) => commentIndex !== index
    );
    setComments(newComments);
  }

  function handleCadastralMunicipalityChange(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    setCadastralMunicipality(event.target.value);
  }

  function handleCadastralNumberChange(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    setCadastralNumber(event.target.value);
  }

  const onNotificationSwitch = () => {
    if (isNotificationSwitched) {
      setNotification("");
    }
    setIsNotificationSwitched(!isNotificationSwitched);
  };

  const onNotificationChange = (notification: string) => {
    setNotification(notification);
  };

  return (
    <Layout>
      <Flex
        flexDir={"column"}
        m={isMobile ? 0 : "2.5vh"}
        p={isMobile ? "2.5vh" : 0}
        w={"100%"}
        h={"100%"}
      >
        <Heading
          size="lg"
          mb={"2.5vh"}
          color={"teal.600"}
          mt={isMobile ? 5 : 0}
        >
          {t("job.newJob")}
        </Heading>

        <TextInput
          isInvalid={jobError}
          errorMessage={t("job.jobTitleError").toString()}
          formLabel={t("job.title")}
          onChange={handleTitleChange}
          value={jobName}
          placeholder={t("job.jobTitlePlaceholder").toString()}
          showError={jobError}
          disabled={isLoading}
        />

        <TextInput
          formLabel={t("job.cadastralMunicipality").toString()}
          onChange={handleCadastralMunicipalityChange}
          value={cadastralMunicipality}
          placeholder={t("job.cadastralMunicipalityPlaceholder").toString()}
          disabled={isLoading}
        />

        <TextInput
          formLabel={t("job.cadastralNumber").toString()}
          onChange={handleCadastralNumberChange}
          value={cadastralNumber}
          placeholder={t("job.cadastralNumberPlaceholder").toString()}
          type="text"
          disabled={isLoading}
        />

        <FormControl w={isMobile ? "100%" : "650px"} mb={5}>
          <FormLabel color={"gray.300"}>{t("job.status")}</FormLabel>
          <Select
            boxShadow={"base"}
            color={"gray.300"}
            backgroundColor={"white"}
            onChange={handleStatusChange}
            defaultValue={JobStatus.Unassigned}
            _placeholder={{ color: "gray.300" }}
            disabled={isLoading}
          >
            {getJobStatusOptionsList().map((option) => {
              return (
                <option key={option} value={option}>
                  {jobStatusMapper(option, t)}
                </option>
              );
            })}
          </Select>
        </FormControl>

        <FormControl p={4} w={isMobile ? "100%" : "650px"} padding={"0"} mb={5}>
          <FormLabel color={"gray.300"}>{t("job.assignJobToWorker")}</FormLabel>
          <SelectMultiple
            isMulti
            name="users"
            options={userOptions}
            placeholder={t("job.assignJobToWorker")}
            closeMenuOnSelect={false}
            value={selectedUsers}
            chakraStyles={multipleSelectStyles}
            noOptionsMessage={() => t("filters.noUserOptions")}
            isDisabled={
              (status !== JobStatus.FieldWorkInProgress &&
                status !== JobStatus.OfficeWorkInProgress) ||
              isLoading
            }
            onChange={(newValue) => {
              const selectedValues: Option[] = newValue.map(
                (option: Option) => option
              );
              setSelectedUsers(selectedValues);
            }}
          />
        </FormControl>
        <FormLabel color={"gray.300"}>{t("job.fileDocumentsTitle")}</FormLabel>
        <FormControl
          w={isMobile ? "100%" : "650px"}
          mb={5}
          isDisabled={isLoading}
          isInvalid={extensionError}
        >
          <Card w={"100%"} backgroundColor={"white"} padding={4}>
            <Button
              colorScheme="teal"
              onClick={handleAddFile}
              isDisabled={isLoading}
            >
              {t("job.addFileDocument")}
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileInputChange}
              multiple
              disabled={isLoading}
            />
            {!!selectedFiles.length && (
              <List gap={3}>
                {selectedFiles.map((file, index) => (
                  <ListItem
                    my={2}
                    display={"flex"}
                    flexDir={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    key={file.lastModified}
                  >
                    <a
                      href={URL.createObjectURL(file)}
                      rel="noreferrer"
                      download={file.name}
                    >
                      <Text>{file.name}</Text>
                    </a>
                    <ListIcon
                      as={MdOutlineClose}
                      color="red"
                      cursor={"pointer"}
                      onClick={() => handleRemoveFile(index)}
                    />
                  </ListItem>
                ))}
              </List>
            )}
            {extensionError && (
              <FormErrorMessage>{t("errors.extensionError")}</FormErrorMessage>
            )}
          </Card>
        </FormControl>
        {!!filterImages(fileUrls).length && (
          <FormLabel color={"gray.300"}>{t("job.jobImagesTitle")}</FormLabel>
        )}

        <SimpleGrid
          columns={[1, 2, 3, 4]}
          spacing="20px"
          w={isMobile ? "100%" : "650px"}
          mb={5}
        >
          {filterImages(fileUrls).map((image, index) => (
            <div style={{ position: "relative" }} key={index}>
              <Box
                bg="teal"
                height="140px"
                width={"140px"}
                backgroundImage={`url(${image.filePath})`}
                backgroundSize="cover"
                backgroundPosition="center"
                cursor={"pointer"}
                onClick={() => openModal(index)}
              ></Box>
            </div>
          ))}
        </SimpleGrid>

        {isModalOpen && (
          <ModalSlider
            isModalOpen={isModalOpen}
            images={filterImages(fileUrls)}
            onClose={() => setIsModalOpen(false)}
            initialImageIndex={currentImageIndex}
          />
        )}

        <JobNotification
          isChecked={isNotificationSwitched}
          onNotificationChange={onNotificationChange}
          onSwitch={onNotificationSwitch}
          notification={notification}
          isDisabled={isLoading}
        />

        <CommentSection
          comments={comments}
          handleAddComment={handleAddComment}
          handleDeleteComment={handleDeleteComment}
          handleEditComment={handleEditComment}
          isDisabled={isLoading}
        />

        <ButtonGroup size="md" isAttached mt={4} colorScheme="teal">
          <Button onClick={handleNewJobButton} isLoading={isLoading}>
            {t("job.createJob")}
          </Button>
        </ButtonGroup>
      </Flex>
    </Layout>
  );
};
export default NewJobPage;
