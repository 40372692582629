interface RequestOptions {
  headers?: HeadersInit;
  body?: BodyInit;
}

async function handleResponse(response: Response): Promise<any> {
  if (response.ok) {
    return response.json();
  } else {
    const status = response.status;
    const message = await response.json();
    throw new Error(JSON.stringify({ status, message }));
  }
}

export async function get(url: string, options?: RequestOptions): Promise<any> {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const jwt = localStorage.getItem("jwt");
  const response = await fetch(baseUrl + url, {
    method: "GET",
    headers: {
      Authorization: jwt || "",
      ...options?.headers,
    },
    body: options?.body,
  });
  return handleResponse(response);
}

export async function post(
  url: string,
  data: any,
  options?: RequestOptions
): Promise<any> {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const response = await fetch(baseUrl + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...options?.headers,
    },
    body: JSON.stringify(data),
  });
  return handleResponse(response);
}

export async function put(
  url: string,
  data: any,
  options?: RequestOptions
): Promise<any> {
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...options?.headers,
    },
    body: JSON.stringify(data),
  });
  return handleResponse(response);
}

export async function del(url: string, options?: RequestOptions): Promise<any> {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const jwt = localStorage.getItem("jwt");
  const response = await fetch(baseUrl + url, {
    method: "DELETE",
    headers: {
      Authorization: jwt || "",
      ...options?.headers,
    },
    body: options?.body,
  });
  return handleResponse(response);
}

export async function postDataWithFormData<T>(
  url: string,
  formData: FormData,
  options?: RequestOptions
): Promise<T> {
  const jwt = localStorage.getItem("jwt");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const response = await fetch(baseUrl + url, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: jwt || "",
      ...options?.headers,
    },
  });

  if (!response.ok) {
    return handleResponse(response);
  }

  const data: T = await response.json();
  return data;
}

export async function putDataWithFormData<T>(
  url: string,
  formData: FormData,
  options?: RequestOptions
): Promise<T> {
  const jwt = localStorage.getItem("jwt");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const response = await fetch(baseUrl + url, {
    method: "PUT",
    body: formData,
    headers: {
      Authorization: jwt || "",
      ...options?.headers,
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to PUT data to ${url}`);
  }

  const data: T = await response.json();
  return data;
}
