import { FiltersType } from "../components/Filters";
import { parseError } from "../lib/errorParser";
import { Job, JobsTableRows } from "../types/job";
import { postDataWithFormData, get, putDataWithFormData, del } from "./api";

export const getJobs = async (
  page: number,
  filters?: FiltersType
): Promise<{
  jobs: JobsTableRows[];
  isLastPage: boolean;
}> => {
  let url = `/job?page=${page}`;

  if (filters?.title) {
    url = url.concat(`&title=${filters.title}`);
  }

  if (filters?.selectedUsers) {
    url = url.concat(`&assignedTo=${filters.selectedUsers}`);
  }

  if (filters?.status) {
    url = url.concat(`&status=${filters.status}`);
  }

  if (filters?.cadastralMunicipality) {
    url = url.concat(`&cadastralMunicipality=${filters.cadastralMunicipality}`);
  }

  if (filters?.cadastralNumber) {
    url = url.concat(`&cadastralNumber=${filters.cadastralNumber}`);
  }

  if (filters?.jobsWithNotification) {
    url = url.concat(`&jobsWithNotification=${!!filters.jobsWithNotification}`);
  }

  try {
    const data = await get(url);
    return data;
  } catch (error) {
    throw new Error(`Error fetching jobs`);
  }
};

export const createJob = async (formData: FormData): Promise<Job> => {
  const url = `/job`;

  try {
    const job: Job = await postDataWithFormData(url, formData);
    return job;
  } catch (error) {
    throw new Error(`Error creating a job`);
  }
};

export const deleteJobById = async (id: Number): Promise<Job> => {
  const url = `/job/${id}`;

  try {
    const response = await del(url);
    return response;
  } catch (error) {
    throw new Error(`Error deleting a job`);
  }
};

export const updateJob = async (
  id: number,
  formData: FormData
): Promise<Job> => {
  const url = `/job/${id}`;

  try {
    const job: Job = await putDataWithFormData(url, formData);
    return job;
  } catch (error) {
    throw new Error(`Error creating a job`);
  }
};

export const getJobById = async (id: number): Promise<Job> => {
  const url = `/job/${id}`;

  try {
    const job: Job = await get(url);
    return job;
  } catch (error) {
    let errorMessage = "Error fetching a job";
    if (error instanceof Error && parseError(error)) {
      const newError = parseError(error);
      throw new Error(
        JSON.stringify({ status: newError?.status, message: newError?.message })
      );
    }

    throw new Error(errorMessage);
  }
};
