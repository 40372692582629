import { Flex, IconButton, Tooltip } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

interface PaginationProps {
  isLastPage: boolean;
  isFirstPage: boolean;
  onNextClick: () => void;
  onPrevClick: () => void;
}

const Pagination = ({
  isLastPage = false,
  isFirstPage = true,
  onPrevClick,
  onNextClick,
}: PaginationProps) => {
  const { t } = useTranslation();
  return (
    <Flex justifyContent="center" m={4} alignItems="center">
      <Tooltip label={t("common.previousPage")}>
        <IconButton
          isDisabled={isFirstPage}
          backgroundColor={"white"}
          icon={<MdKeyboardArrowLeft />}
          mr={4}
          aria-label={""}
          onClick={() => onPrevClick()}
        />
      </Tooltip>
      <Tooltip label={t("common.nextPage")}>
        <IconButton
          isDisabled={isLastPage}
          backgroundColor={"white"}
          icon={<MdKeyboardArrowRight />}
          mr={4}
          aria-label={""}
          onClick={() => onNextClick()}
        />
      </Tooltip>
    </Flex>
  );
};

export default Pagination;
