export const parseError = (error: Error) => {
  try {
    const errorString = error.toString().substring(7);

    if (errorString) {
      const data = JSON.parse(errorString);
      const { status, message } = data;
      return { status, message };
    }
    return null;
  } catch (error) {
    return null;
  }
};
