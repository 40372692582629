import { parseError } from "../lib/errorParser";
import { User } from "../types/user";
import { post } from "./api";

export const loginUser = async (username: string, password: string) => {
  const url = `/auth/login`;
  const data = {
    username,
    password,
  };

  try {
    const userData: { token: string; user: User } = await post(url, data);
    localStorage.setItem("jwt", userData.token);
    localStorage.setItem("user", JSON.stringify(userData.user));
    return userData.user;
  } catch (error) {
    let errorMessage = "Error logging in user";
    if (error instanceof Error && parseError(error)) {
      const newError = parseError(error);
      throw new Error(
        JSON.stringify({ status: newError?.status, message: newError?.message })
      );
    }

    throw new Error(errorMessage);
  }
};
