import { parseError } from "../lib/errorParser";
import { User, UsersTableRows } from "../types/user";
import { get, postDataWithFormData, putDataWithFormData, del } from "./api";

export const getUsersByType = async (type: number): Promise<User[]> => {
  const url = `/user/type/${type}`;

  try {
    const users = await get(url);
    return users;
  } catch (error) {
    throw new Error(`Error fetching users by type`);
  }
};

export const getUsers = async (
  page: number
): Promise<{
  users: UsersTableRows[];
  isLastPage: boolean;
}> => {
  const url = `/user?page=${page}`;

  try {
    const data = await get(url);
    return data;
  } catch (error) {
    throw new Error(`Error fetching users`);
  }
};

export const getWorkers = async (): Promise<User[]> => {
  const url = `/user/workers/all`;

  try {
    const users = await get(url);
    return users;
  } catch (error) {
    throw new Error(`Error fetching office and fields workers`);
  }
};

export const getUserById = async (id: number): Promise<User> => {
  const url = `/user/${id}`;

  try {
    const user = await get(url);
    return user;
  } catch (error) {
    let errorMessage = `Error fetching user`;
    if (error instanceof Error && parseError(error)) {
      const newError = parseError(error);
      throw new Error(
        JSON.stringify({ status: newError?.status, message: newError?.message })
      );
    }
    throw new Error(errorMessage);
  }
};

export const createUser = async (formData: FormData): Promise<any> => {
  const url = `/user`;

  try {
    const user = await postDataWithFormData(url, formData);
    return user;
  } catch (error) {
    let errorMessage = `Error creating user`;
    if (error instanceof Error && parseError(error)) {
      const newError = parseError(error);
      throw new Error(
        JSON.stringify({ status: newError?.status, message: newError?.message })
      );
    }

    throw new Error(errorMessage);
  }
};

export const deleteUserById = async (id: Number): Promise<any> => {
  const url = `/user/${id}`;

  try {
    const res = await del(url);
    return res;
  } catch (error) {
    throw new Error(`Error deleting user`);
  }
};

export const updateUser = async (
  formData: FormData,
  id: number
): Promise<any> => {
  const url = `/user/${id}`;

  try {
    const user = await putDataWithFormData(url, formData);
    return user;
  } catch (error) {
    throw new Error(`Error updating user`);
  }
};
