import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    gray: {
      100: "#F1F1F1",
      200: "#F6F6F6",
      300: "#747474",
    },
    blue: {
      900: "#4340DA",
    },
  },
});

export const multipleSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    background: "white",
    boxShadow: "base",
    color: "gray.300",
    _placeholder: { color: "gray.300", opacity: 0 },
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    background: "white",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "gray.300",
  }),
  menuList: (provided: any) => ({
    ...provided,
    boxShadow: "base",
    outline: `2px solid ${theme.colors.blue[500]}`,
    minHeight: "80px",
  }),
};
