import {
  FormControl,
  FormLabel,
  Switch,
  Textarea,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface JobNotificationProps {
  isChecked: boolean;
  isDisabled?: boolean;
  onSwitch: () => void;
  notification: string;
  onNotificationChange: (notification: string) => void;
}

const JobNotification = ({
  isChecked,
  onSwitch,
  notification,
  onNotificationChange,
  isDisabled = false,
}: JobNotificationProps) => {
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <>
      <FormControl
        display="flex"
        alignItems="center"
        mb={5}
        w={isMobile ? "100%" : "650px"}
        color={"gray.300"}
        isDisabled={isDisabled}
      >
        <FormLabel htmlFor="notification-switch" mb="0">
          {t("job.notificationTitle")}
        </FormLabel>
        <Switch
          colorScheme="teal"
          id="notification-switch"
          isChecked={isChecked}
          onChange={onSwitch}
        />
      </FormControl>
      <FormControl
        w={isMobile ? "100%" : "650px"}
        mb={5}
        isDisabled={!isChecked}
      >
        <Textarea
          value={notification}
          onChange={(event) => onNotificationChange(event.target.value)}
          placeholder={t("job.addNotification").toString()}
          color="gray.300"
          backgroundColor="white"
          boxShadow="base"
          _placeholder={{ color: "gray.300" }}
          disabled={isDisabled || !isChecked}
        />
      </FormControl>
    </>
  );
};

export default JobNotification;
