import {
  Button,
  AlertDialog as Alert,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { useRef } from "react";

type AlertProps = {
  title: string;
  description: string;
  cancelButtonText: string;
  acceptButtonText: string;
  onCancel: () => void;
  onSuccess: () => void;
  isOpen: boolean;
};

const AlertDialog = ({
  title,
  description,
  cancelButtonText,
  acceptButtonText,
  onCancel,
  onSuccess,
  isOpen,
}: AlertProps) => {
  const cancelRef = useRef(null);

  return (
    <>
      <Alert isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onCancel}>
        <AlertDialogOverlay>
          <AlertDialogContent mx={2} my={10}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{description}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCancel}>
                {cancelButtonText}
              </Button>
              <Button colorScheme="red" onClick={onSuccess} ml={3}>
                {acceptButtonText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </Alert>
    </>
  );
};

export default AlertDialog;
