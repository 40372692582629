import { Flex, useBreakpointValue } from "@chakra-ui/react";
import { ReactNode } from "react";
import Sidebar from "./SideBar";
import SidebarMobile from "./SidebarMobile";

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Flex bg="gray.100" minH="100vh" w="100%">
      {isMobile ? <SidebarMobile /> : <Sidebar />}

      <Flex w="100%" height="100%" mt={isMobile ? 6 : 0}>
        {children}
      </Flex>
    </Flex>
  );
};

export default Layout;
