import { JobStatus, JobStatusWithFilters } from "../types/job";
import { TFunction } from "i18next";
import { UserType } from "../types/user";

export const jobStatusMapper = (
  status: JobStatus | JobStatusWithFilters,
  t: TFunction<"translation", undefined, "translation">
) => {
  switch (status) {
    case 0: {
      return t("job.all");
    }
    case 1: {
      return t("job.unassigned");
    }
    case 2: {
      return t("job.fieldWorkInProgress");
    }
    case 3: {
      return t("job.fieldWorkCompleted");
    }
    case 4: {
      return t("job.officeWorkInProgress");
    }
    case 5: {
      return t("job.officeWorkCompleted");
    }
    case 6: {
      return t("job.archived");
    }
    case 7: {
      return t("job.inTheCadastre");
    }
    case 8: {
      return t("job.billing");
    }
    case 9: {
      return t("job.onHold");
    }
  }
};

export const userTypeMapper = (
  type: UserType,
  t: TFunction<"translation", undefined, "translation">
) => {
  switch (type) {
    case 1: {
      return t("user.admin");
    }
    case 2: {
      return t("user.officeWorker");
    }
    case 3: {
      return t("user.fieldWorker");
    }
  }
};
