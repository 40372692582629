import { Comment } from "./comment";
import { User } from "./user";

export type JobsOnUsers = {
  user: User;
  userId: number;
  job: Job;
  jobId: number;
};

export type Job = {
  id: number;
  title: string;
  status: number;
  createdAt: Date;
  updatedAt: Date;
  jobFiles: JobFile[];
  assignedTo?: JobsOnUsers[];
  comments: Comment[];
  cadastralNumber?: number;
  cadastralMunicipality?: string;
  notification?: string;
};

export type JobFile = {
  id?: number;
  filePath: string;
  jobId?: number;
  name?: string;
  type?: string;
  lastModified?: number;
};

export enum JobStatus {
  Unassigned = 1,
  FieldWorkInProgress,
  FieldWorkCompleted,
  OfficeWorkInProgress,
  OfficeWorkCompleted,
  Archived,
  InTheCadastre,
  Billing,
  OnHold,
}

export enum JobStatusWithFilters {
  All = 0,
  Unassigned = JobStatus.Unassigned,
  FieldWorkInProgress = JobStatus.FieldWorkInProgress,
  FieldWorkCompleted = JobStatus.FieldWorkCompleted,
  OfficeWorkInProgress = JobStatus.OfficeWorkInProgress,
  OfficeWorkCompleted = JobStatus.OfficeWorkCompleted,
  Archived = JobStatus.Archived,
  InTheCadastre = JobStatus.InTheCadastre,
  Billing = JobStatus.Billing,
  OnHold = JobStatus.OnHold,
}

export type JobsTableRows = {
  [key: string | number]: string | number;
  id: number;
  status: JobStatus;
  title: string;
  createdAt: string;
  updatedAt: string;
};
