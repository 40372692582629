import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Image,
  IconButton,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { JobFile } from "../../../types/job";

interface ModalSliderProps {
  isModalOpen: boolean;
  onClose: () => void;
  initialImageIndex: number;
  images: JobFile[];
}

const ModalSlider = ({
  isModalOpen,
  onClose,
  initialImageIndex,
  images,
}: ModalSliderProps) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(initialImageIndex);
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentImageIndex(initialImageIndex);
  }, [initialImageIndex]);

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextImage = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <Modal isOpen={isModalOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent w="95%" h="100%">
        <ModalHeader>{t("job.imageGallery")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          height="100%"
          style={{ display: "flex", overflow: "hidden" }}
        >
          <Flex
            width="90%"
            flexGrow={1}
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
            position="relative"
          >
            {currentImageIndex > 0 && (
              <IconButton
                aria-label="Previous Image"
                icon={<AiOutlineLeft />}
                size="lg"
                position="absolute"
                left={4}
                onClick={handlePrevImage}
                colorScheme="teal"
              />
            )}

            <Image
              src={images[currentImageIndex || 0].filePath}
              maxH="100%"
              w="100%"
              objectFit="contain"
            />

            {currentImageIndex < images.length - 1 && (
              <IconButton
                aria-label="Next Image"
                icon={<AiOutlineRight />}
                size="lg"
                position="absolute"
                right={4}
                onClick={handleNextImage}
                colorScheme="teal"
              />
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalSlider;
