import { useContext, useEffect, useState } from "react";
import {
  FormControl,
  Select,
  SimpleGrid,
  Input,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { MultiValue, Select as SelectMultiple } from "chakra-react-select";
import { multipleSelectStyles } from "../lib/theme";
import { useTranslation } from "react-i18next";
import { jobStatusMapper } from "../lib/mappers";
import { JobStatusWithFilters } from "../types/job";
import { getWorkers } from "../services/user";
import { AppContext } from "../context/AppContext";
import { UserType } from "../types/user";
import { getJobStatusOptionsList } from "../lib/utils";

type Option = {
  value: string;
  label: string;
};

export type FiltersType = {
  status: JobStatusWithFilters;
  title: string;
  selectedUsers: string;
  cadastralMunicipality: string;
  cadastralNumber: string;
  jobsWithNotification: boolean;
};

type FiltersProps = {
  onChange: (filters: FiltersType) => void;
};

const Filters = ({ onChange }: FiltersProps) => {
  const { user } = useContext(AppContext);
  const [jobTitle, stetJobTitle] = useState<string>("");
  const [cadastralMunicipality, setCadastralMunicipality] =
    useState<string>("");
  const [cadastralNumber, setCadastralNumber] = useState<string>("");
  const [userOptions, setUserOptions] = useState<Option[]>([]);
  const [status, setStatus] = useState(JobStatusWithFilters.All);
  const [selectedUsers, setSelectedUsers] = useState<Option[]>([]);
  const [jobsWithNotification, setJobsWithNotification] =
    useState<boolean>(false);

  useEffect(() => {
    const fetchWorkers = async () => {
      const workersData = await getWorkers();
      const newUserOptions: Option[] = workersData.map((user) => {
        return {
          value: user.id.toString(),
          label: `${user.firstName} ${user.lastName}`,
        };
      });
      setUserOptions(newUserOptions);
    };
    if (user?.type === UserType.Admin) {
      fetchWorkers();
    }
  }, []);

  const onTitleChange = async (title: string) => {
    stetJobTitle(title);
    onChange({
      status,
      title,
      selectedUsers: selectedUsers.map((u) => u.value).join(","),
      cadastralMunicipality,
      cadastralNumber,
      jobsWithNotification,
    });
  };

  const onCadastralMunicipalityChange = async (value: string) => {
    setCadastralMunicipality(value);
    onChange({
      status,
      title: jobTitle,
      selectedUsers: selectedUsers.map((u) => u.value).join(","),
      cadastralMunicipality: value,
      cadastralNumber,
      jobsWithNotification,
    });
  };

  const onCadastralNumberChange = async (value: string) => {
    setCadastralNumber(value);
    onChange({
      status,
      title: jobTitle,
      selectedUsers: selectedUsers.map((u) => u.value).join(","),
      cadastralMunicipality,
      cadastralNumber: value,
      jobsWithNotification,
    });
  };

  const onStatusChange = async (jobStatus: JobStatusWithFilters) => {
    setStatus(Number(jobStatus));
    onChange({
      status: Number(jobStatus),
      title: jobTitle,
      selectedUsers: selectedUsers.map((u) => u.value).join(","),
      cadastralMunicipality,
      cadastralNumber,
      jobsWithNotification,
    });
  };

  const onSelectedUsersChange = async (selectedUsers: MultiValue<Option>) => {
    const selectedValues: Option[] = selectedUsers.map(
      (option: Option) => option
    );
    setSelectedUsers(selectedValues);
    onChange({
      status,
      title: jobTitle,
      selectedUsers: selectedValues.map((u) => u.value).join(","),
      cadastralMunicipality,
      cadastralNumber,
      jobsWithNotification,
    });
  };

  const onSwitch = async () => {
    onChange({
      status,
      title: jobTitle,
      selectedUsers: selectedUsers.map((u) => u.value).join(","),
      cadastralMunicipality,
      cadastralNumber,
      jobsWithNotification: !jobsWithNotification,
    });
    setJobsWithNotification(!jobsWithNotification);
  };

  const { t } = useTranslation();

  return (
    <SimpleGrid
      columns={[1, 2, 3]}
      spacing="4"
      w={"100%"}
      alignItems={"center"}
      py={4}
    >
      <FormControl w={"100%"} color={"gray.300"}>
        <Input
          color={"gray.300"}
          type="text"
          backgroundColor={"white"}
          value={jobTitle}
          onChange={(event) => onTitleChange(event.target.value)}
          placeholder={t("filters.byTitle").toString()}
          boxShadow={"base"}
          _placeholder={{ color: "gray.300" }}
        />
      </FormControl>
      <FormControl w={"100%"} color={"gray.300"}>
        <Input
          color={"gray.300"}
          type="text"
          backgroundColor={"white"}
          value={cadastralMunicipality}
          onChange={(event) =>
            onCadastralMunicipalityChange(event.target.value)
          }
          placeholder={t("job.cadastralMunicipality").toString()}
          boxShadow={"base"}
          _placeholder={{ color: "gray.300" }}
        />
      </FormControl>
      <FormControl w={"100%"} color={"gray.300"}>
        <Input
          color={"gray.300"}
          type="text"
          backgroundColor={"white"}
          value={cadastralNumber}
          onChange={(event) => onCadastralNumberChange(event.target.value)}
          placeholder={t("job.cadastralNumber").toString()}
          boxShadow={"base"}
          _placeholder={{ color: "gray.300" }}
        />
      </FormControl>

      {user?.type === UserType.Admin && (
        <>
          <FormControl w={"100%"}>
            <SelectMultiple
              isMulti
              name="users"
              options={userOptions}
              placeholder={t("filters.byUser")}
              closeMenuOnSelect={false}
              value={selectedUsers}
              chakraStyles={multipleSelectStyles}
              onChange={(newValue) => onSelectedUsersChange(newValue)}
              noOptionsMessage={() => t("filters.noUserOptions")}
            />
          </FormControl>
          <FormControl w={"100%"}>
            <Select
              boxShadow={"base"}
              color={"gray.300"}
              backgroundColor={"white"}
              onChange={(event) => onStatusChange(Number(event.target.value))}
              value={status}
              _placeholder={{ color: "gray.300" }}
            >
              {getJobStatusOptionsList(true).map((option) => {
                return (
                  <option key={option} value={option}>
                    {jobStatusMapper(option, t)}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </>
      )}
      <FormControl
        w={"100%"}
        color={"gray.300"}
        display={"flex"}
        flexDir={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        py={2}
        px={4}
        backgroundColor={"white"}
        boxShadow={"base"}
        borderRadius={"md"}
      >
        <FormLabel htmlFor="notification-switch" mb="0" flexGrow={1}>
          {t("job.jobsWithNotification")}
        </FormLabel>
        <Switch
          colorScheme="teal"
          id="notification-switch"
          isChecked={jobsWithNotification}
          onChange={onSwitch}
        />
      </FormControl>
    </SimpleGrid>
  );
};

export default Filters;
