import {
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Image,
} from "@chakra-ui/react";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { loginUser } from "../services/auth";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { ServerResponse } from "../types/ServerResponse";
import { parseError } from "../lib/errorParser";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const { t } = useTranslation();
  const { setUser, setAlertMessage } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    const jwt = localStorage.getItem("jwt");
    if (jwt) navigate(`/jobs`);
  }, []);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setUsername(e.target.value);
  };
  function handlePasswordChange(e: ChangeEvent<HTMLInputElement>): void {
    setPassword(e.target.value);
  }

  const handleSubmit = async (event: FormEvent<HTMLButtonElement>) => {
    if (!username) {
      setUsernameError(true);
      return;
    }
    setUsernameError(false);
    if (!password) {
      setPasswordError(true);
      return;
    }

    setPasswordError(false);

    try {
      setIsLoading(true);
      const user = await loginUser(username, password);
      setUser(user);
      setIsLoading(false);
      navigate(`/jobs`);
    } catch (error: any) {
      setIsLoading(false);
      if (error instanceof Error) {
        setIsLoading(false);
        if (error instanceof Error) {
          const errorData = parseError(error);
          if (errorData?.status === ServerResponse.Unauthorized) {
            setAlertMessage(t("errors.loginError").toString());
          } else {
            setAlertMessage(t("errors.serverError").toString());
          }
        }
      }
    }
  };

  return (
    <Flex
      w={"100%"}
      h={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDir={"column"}
    >
      <Card
        bg="white"
        maxH="500px"
        maxW={"400px"}
        display={"flex"}
        flexDir={"column"}
        alignItems={"center"}
        boxShadow={"lg"}
        borderRadius={"16px"}
        m={4}
      >
        <CardBody>
          <FormControl mb={5} isInvalid={usernameError}>
            <Card
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              m={10}
            >
              <Image w="120px" src="/surveyor.png" />
            </Card>

            <FormLabel color={"gray.300"}>{t("common.username")}</FormLabel>
            <Input
              type="text"
              backgroundColor={"gray.200"}
              value={username}
              onChange={handleInputChange}
              placeholder={t("login.usernamePlaceholder").toString()}
            />
            {usernameError && (
              <FormErrorMessage>{t("login.usernameError")}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl mb={5} isInvalid={passwordError}>
            <FormLabel color={"gray.300"}>{t("common.password")}</FormLabel>
            <InputGroup size="md">
              <Input
                backgroundColor={"gray.200"}
                value={password}
                pr="4.5rem"
                type={showPassword ? "text" : "password"}
                placeholder={t("login.passwordPlaceholder").toString()}
                onChange={handlePasswordChange}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClickShowPassword}>
                  {showPassword
                    ? t("login.hidePassword")
                    : t("login.showPassword")}
                </Button>
              </InputRightElement>
            </InputGroup>
            {passwordError && (
              <FormErrorMessage>{t("login.passwordError")}</FormErrorMessage>
            )}
          </FormControl>
          <Button
            mt={4}
            colorScheme="teal"
            isLoading={isLoading}
            type="submit"
            onClick={handleSubmit}
          >
            {t("login.loginButton")}
          </Button>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default LoginForm;
