import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { deleteJobById, getJobById, updateJob } from "../services/job";
import { useNavigate, useParams } from "react-router-dom";
import { Job, JobFile, JobStatus } from "../types/job";
import Layout from "../components/Layout";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Text,
  List,
  ListIcon,
  ListItem,
  Select,
  SimpleGrid,
  useBreakpointValue,
  FormErrorMessage,
} from "@chakra-ui/react";
import { jobStatusMapper } from "../lib/mappers";
import { Select as SelectMultiple } from "chakra-react-select";
import { useTranslation } from "react-i18next";
import { getUsersByType } from "../services/user";
import { UserType } from "../types/user";
import TextInput from "../components/common/TextInput/TextInput";
import { multipleSelectStyles } from "../lib/theme";
import CommentSection from "../components/common/CommentSection/CommentSection";
import ModalSlider from "../components/common/ModalSlider/ModalSlider";
import { MdOutlineClose } from "react-icons/md";
import { Comment } from "../types/comment";
import JobNotification from "../components/JobNotification";
import {
  areValidExtensions,
  filterImages,
  getJobStatusOptionsList,
  isValidUploadSize,
} from "../lib/utils";
import { AppContext } from "../context/AppContext";
import { parseError } from "../lib/errorParser";
import { AlertStatus, ServerResponse } from "../types/ServerResponse";
import AlertDialog from "../components/common/AlertDialog/AlertDialog";

type Option = {
  value: string;
  label: string;
};

const JobDetailPage = () => {
  const [job, setJob] = useState<Job | null>(null);
  const [cadastralMunicipality, setCadastralMunicipality] =
    useState<string>("");
  const [cadastralNumber, setCadastralNumber] = useState<string>("");
  const [jobName, setJobName] = useState("");
  const [userOptions, setUserOptions] = useState<Option[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<Option[]>([]);
  const [status, setStatus] = useState(JobStatus.Unassigned);
  const [jobError, setJobError] = useState(false);
  const [extensionError, setExtensionError] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileUrls, setFileUrls] = useState<JobFile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [comments, setComments] = useState<Comment[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [removedImages, setRemovedImages] = useState<number[]>([]);
  const [isNotificationSwitched, setIsNotificationSwitched] =
    useState<boolean>(false);
  const [notification, setNotification] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false);
  const [updatedCommentIndex, setUpdatedCommentIndex] = useState<number | null>(
    null
  );
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { user, setAlertMessage } = useContext(AppContext);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    return () => {
      setIsModalOpen(false);
    };
  }, []);

  useEffect(() => {
    async function fetchJob() {
      try {
        const job = await getJobById(Number(id));
        setJob(job);
      } catch (error) {
        if (error instanceof Error) {
          const errorData = parseError(error);

          if (errorData?.status === ServerResponse.Unauthorized) {
            navigate("/unauthorized");
          }
          if (errorData?.status === ServerResponse.NotFound) {
            navigate("/page-not-found");
          }
        }
      }
    }
    fetchJob();
  }, [id]);

  useEffect(() => {
    if (job) {
      setStatus(Number(job.status));
      setJobName(job.title);
      setCadastralMunicipality(job.cadastralMunicipality || "");
      setCadastralNumber(job.cadastralNumber?.toString() || "");
      if (job.notification) {
        setNotification(job.notification);
        setIsNotificationSwitched(true);
      }
      if (job.assignedTo) {
        setSelectedUsers(
          job.assignedTo.map((assigned) => ({
            value: assigned?.userId.toString(),
            label: `${assigned.user.firstName} ${assigned.user.lastName}`,
          }))
        );
      }
      setComments(job.comments);
      setFileUrls([
        ...job.jobFiles.map((image) => {
          return {
            id: image.id,
            jobId: image.jobId,
            filePath: image.filePath,
            name: image.name,
            type: image.type,
          };
        }),
      ]);
    }
  }, [job]);

  useEffect(() => {
    const updateOptions = async () => {
      if (
        Number(status) === JobStatus.FieldWorkInProgress ||
        Number(status) === JobStatus.OfficeWorkInProgress
      ) {
        const users = await getUsersByType(
          Number(status) === JobStatus.FieldWorkInProgress
            ? UserType.FieldWorker
            : UserType.OfficeWorker
        );
        const newUserOptions: Option[] = users.map((user) => {
          return {
            value: user.id.toString(),
            label: `${user.firstName} ${user.lastName}`,
          };
        });

        setUserOptions(newUserOptions);
      }
    };
    updateOptions();
  }, [status]);

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setJobName(event.target.value);
    if (event.target.value) setJobError(false);
  };

  const handleStatusChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    setSelectedUsers([]);
    setStatus(Number(event.target.value));
  };

  const handleAddFile = () => {
    if (fileInputRef?.current) {
      fileInputRef?.current.click();
    }
  };

  const openModal = (index: number) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setExtensionError(false);
    const files = event.target.files;
    if (files && files.length > 0) {
      const newFiles: File[] = Array.from(files);

      const validExtensions = areValidExtensions(newFiles);
      if (!validExtensions) {
        setExtensionError(true);
        return;
      }

      const newFileUrls: JobFile[] = newFiles.map((file) => {
        return {
          filePath: URL.createObjectURL(file),
          name: file.name,
          type: file.type,
          lastModified: file.lastModified,
        };
      });

      setSelectedFiles([...selectedFiles, ...newFiles]);
      setFileUrls([...fileUrls, ...newFileUrls]);
    }
  };

  const handleAddComment = (commentText: string) => {
    if (updatedCommentIndex !== null) {
      const newComments = [...comments];
      newComments[updatedCommentIndex] = {
        ...newComments[updatedCommentIndex],
        text: commentText,
      };
      setComments(newComments);
      setUpdatedCommentIndex(null);
      return;
    }
    if (commentText.trim() !== "") {
      setComments((prevComments) => [...prevComments, { text: commentText }]);
    }
  };

  const handleUpdateJob = async (): Promise<void> => {
    if (!jobName || !jobName.trim()) {
      setJobError(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    if (!isValidUploadSize(selectedFiles)) {
      setAlertMessage(
        t("errors.filesTooBigError").toString(),
        AlertStatus.Error
      );
      return;
    }
    try {
      setIsLoading(true);
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("files", file);
      });
      formData.append("title", jobName.trim());
      formData.append("cadastralMunicipality", cadastralMunicipality);
      formData.append("cadastralNumber", cadastralNumber);
      formData.append("notification", notification);
      formData.append("status", status.toString());
      comments.forEach((comment) => {
        formData.append("comments", JSON.stringify(comment));
      });
      selectedUsers.forEach((user) => {
        formData.append("selectedUsers", user.value);
      });
      removedImages.forEach((imageId) => {
        formData.append("removedImages", imageId.toString());
      });
      await updateJob(Number(id), formData);
      setIsLoading(false);
      setAlertMessage(
        t("successResponse.jobUpdate").toString(),
        AlertStatus.Success
      );
      navigate("/jobs");
    } catch (error) {
      setIsLoading(false);
      setAlertMessage(t("errors.serverError").toString(), AlertStatus.Error);
    }
  };

  const handleDeleteJob = async (): Promise<void> => {
    setShowAlertDialog(false);
    try {
      if (user?.type !== UserType.Admin) return;
      setIsLoading(true);
      if (job?.id) {
        await deleteJobById(job.id);
      }
      setAlertMessage(
        t("successResponse.jobDelete").toString(),
        AlertStatus.Success
      );
      setIsLoading(false);
      navigate("/jobs");
    } catch (error) {
      setIsLoading(false);
      setAlertMessage(t("errors.serverError").toString(), AlertStatus.Error);
    }
  };

  const handleRemoveFile = (index: number) => {
    if (isLoading) return;
    const currentFile = fileUrls[index];
    if (currentFile && currentFile.id !== undefined && currentFile.jobId) {
      setRemovedImages([...removedImages, currentFile.id]);
    }
    const updatedSelectedFiles = [
      ...selectedFiles.filter(
        (file) => file.lastModified !== currentFile.lastModified
      ),
    ];

    const newFileUrls = [...fileUrls];
    newFileUrls.splice(index, 1);

    setFileUrls(newFileUrls);
    setSelectedFiles(updatedSelectedFiles);
  };

  function handleEditComment(index: number): void {
    setUpdatedCommentIndex(index);
  }

  function handleDeleteComment(index: number): void {
    const newComments = comments.filter(
      (c, commentIndex) => commentIndex !== index
    );
    setComments(newComments);
  }

  function handleCadastralMunicipalityChange(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    setCadastralMunicipality(event.target.value);
  }

  function handleCadastralNumberChange(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    setCadastralNumber(event.target.value);
  }

  const onNotificationSwitch = () => {
    if (isNotificationSwitched) {
      setNotification("");
    }
    setIsNotificationSwitched(!isNotificationSwitched);
  };

  const onNotificationChange = (notification: string) => {
    setNotification(notification);
  };

  const calculateIsDisabled = (option: number): boolean => {
    if (user?.type === UserType.FieldWorker) {
      return option !== JobStatus.FieldWorkCompleted;
    }
    if (user?.type === UserType.OfficeWorker) {
      return option !== JobStatus.OfficeWorkCompleted;
    }
    return false;
  };
  return (
    <Layout>
      <Flex
        flexDir={"column"}
        m={isMobile ? 0 : "2.5vh"}
        p={isMobile ? "2.5vh" : 0}
        w={"100%"}
        h={"100%"}
      >
        <Heading
          size="lg"
          mb={"2.5vh"}
          mt={isMobile ? 5 : 0}
          color={"teal.600"}
        >
          {t("job.jobDetail")}
        </Heading>

        <TextInput
          isInvalid={jobError}
          errorMessage={t("job.jobTitleError").toString()}
          formLabel={t("job.title")}
          onChange={handleTitleChange}
          value={jobName}
          placeholder={t("job.jobTitlePlaceholder").toString()}
          showError={jobError}
          disabled={isLoading}
        />

        <TextInput
          formLabel={t("job.cadastralMunicipality").toString()}
          onChange={handleCadastralMunicipalityChange}
          value={cadastralMunicipality}
          placeholder={t("job.cadastralMunicipalityPlaceholder").toString()}
          disabled={isLoading}
        />

        <TextInput
          formLabel={t("job.cadastralNumber").toString()}
          onChange={handleCadastralNumberChange}
          value={cadastralNumber}
          placeholder={t("job.cadastralNumberPlaceholder").toString()}
          disabled={isLoading}
        />

        <FormControl w={isMobile ? "100%" : "650px"} mb={5}>
          <FormLabel color={"gray.300"}>{t("job.status")}</FormLabel>
          <Select
            boxShadow={"base"}
            color={"gray.300"}
            backgroundColor={"white"}
            onChange={handleStatusChange}
            value={status}
            _placeholder={{ color: "gray.300" }}
            disabled={isLoading}
          >
            {getJobStatusOptionsList().map((option) => {
              const isDisabled = calculateIsDisabled(option);
              return (
                <option
                  key={option}
                  value={option}
                  disabled={isDisabled}
                  style={{
                    color: isDisabled ? "lightgray" : "inherit",
                  }}
                >
                  {jobStatusMapper(option, t)}
                </option>
              );
            })}
          </Select>
        </FormControl>
        <FormControl p={4} w={isMobile ? "100%" : "650px"} padding={"0"} mb={5}>
          <FormLabel color={"gray.300"}>{t("job.assignJobToWorker")}</FormLabel>
          <SelectMultiple
            isMulti
            name="users"
            options={userOptions}
            placeholder={t("job.assignJobToWorker")}
            closeMenuOnSelect={false}
            value={selectedUsers}
            chakraStyles={multipleSelectStyles}
            noOptionsMessage={() => t("filters.noUserOptions")}
            isDisabled={
              (status !== JobStatus.FieldWorkInProgress &&
                status !== JobStatus.OfficeWorkInProgress) ||
              user?.type !== UserType.Admin ||
              isLoading
            }
            onChange={(newValue) => {
              const selectedValues: Option[] = newValue.map(
                (option: Option) => option
              );
              setSelectedUsers(selectedValues);
            }}
          />
        </FormControl>

        <FormControl
          w={isMobile ? "100%" : "650px"}
          mb={5}
          isDisabled={isLoading}
          isInvalid={extensionError}
        >
          <FormLabel color={"gray.300"}>
            {t("job.fileDocumentsTitle")}
          </FormLabel>
          <Card w={"100%"} backgroundColor={"white"} padding={4}>
            <Button
              colorScheme="teal"
              onClick={handleAddFile}
              isDisabled={isLoading}
            >
              {t("job.addFileDocument")}
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileInputChange}
              multiple
            />
            {!!fileUrls.length && (
              <List gap={3}>
                {fileUrls.map((file, index) => (
                  <ListItem
                    my={2}
                    display={"flex"}
                    flexDir={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    key={file.filePath || file.id || file.name}
                  >
                    <a
                      href={file.filePath}
                      download={file.name}
                      rel="noreferrer"
                    >
                      <Text wordBreak={"break-all"}>{file.name}22</Text>
                    </a>
                    <ListIcon
                      as={MdOutlineClose}
                      color="red"
                      cursor={"pointer"}
                      onClick={() => handleRemoveFile(index)}
                    />
                  </ListItem>
                ))}
              </List>
            )}
            {extensionError && (
              <FormErrorMessage>{t("errors.extensionError")}</FormErrorMessage>
            )}
          </Card>
        </FormControl>
        {!!filterImages(fileUrls).length && (
          <FormLabel color={"gray.300"}>{t("job.jobImagesTitle")}</FormLabel>
        )}

        <SimpleGrid columns={[1, 2, 3, 4]} spacing="20px" maxW={"650px"} mb={5}>
          {filterImages(fileUrls).map((file, index) => {
            return (
              <div style={{ position: "relative" }} key={index}>
                <Box
                  bg="teal"
                  height="140px"
                  width={"140px"}
                  backgroundImage={`url(${file.filePath})`}
                  backgroundSize="cover"
                  backgroundPosition="center"
                  cursor={"pointer"}
                  onClick={() => openModal(index)}
                ></Box>
              </div>
            );
          })}
        </SimpleGrid>

        {isModalOpen && (
          <ModalSlider
            isModalOpen={isModalOpen}
            images={filterImages(fileUrls)}
            onClose={() => setIsModalOpen(false)}
            initialImageIndex={currentImageIndex}
          />
        )}

        <JobNotification
          isChecked={isNotificationSwitched}
          onNotificationChange={onNotificationChange}
          onSwitch={onNotificationSwitch}
          notification={notification}
          isDisabled={isLoading}
        />

        <CommentSection
          comments={comments}
          handleAddComment={handleAddComment}
          handleDeleteComment={handleDeleteComment}
          handleEditComment={handleEditComment}
          isDisabled={isLoading}
        />

        <ButtonGroup size="md" isAttached mt={10}>
          <Button
            onClick={handleUpdateJob}
            isLoading={isLoading}
            colorScheme="teal"
          >
            {t("common.saveChanges")}
          </Button>
          {user?.type === UserType.Admin && (
            <Button
              onClick={() => setShowAlertDialog(true)}
              isLoading={isLoading}
              colorScheme="red"
              mx={5}
            >
              {t("job.deleteJob")}
            </Button>
          )}
        </ButtonGroup>
        {showAlertDialog && (
          <AlertDialog
            title={t("job.jobDeletion")}
            description={t("job.deleteJobAreYouSure")}
            acceptButtonText={t("job.deleteJob")}
            cancelButtonText={t("job.cancel")}
            onCancel={() => setShowAlertDialog(false)}
            isOpen={showAlertDialog}
            onSuccess={handleDeleteJob}
          />
        )}
      </Flex>
    </Layout>
  );
};

export default JobDetailPage;
