import {
  Alert,
  AlertIcon,
  AlertTitle,
  SystemStyleObject,
} from "@chakra-ui/react";
import { useEffect, useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";

type AlertMessageProps = {
  status?: "error" | "info" | "warning" | "success" | "loading" | undefined;
  sx?: SystemStyleObject | undefined;
};

const AlertMessage = ({ sx }: AlertMessageProps) => {
  const { showAlertMessage, closeAlertMessage, alertTitle, alertStatus } =
    useContext(AppContext);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (showAlertMessage) {
      setShowAlert(true);
      setTimeout(() => {
        closeAlertMessage();
      }, 3000);
    } else {
      setShowAlert(false);
    }
  }, [showAlertMessage, closeAlertMessage]);

  return (
    <div
      style={{
        position: "fixed",
        bottom: showAlert ? "50px" : "-200px",
        left: "50%",
        transform: "translateX(-50%)",
        maxWidth: "400px",
        zIndex: 1000,
        transition: "bottom 0.5s ease-in-out",
      }}
    >
      <div>
        <Alert
          status={alertStatus}
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          sx={{
            borderRadius: "10px",
            ...sx,
          }}
        >
          <AlertIcon boxSize="40px" mr={0} />
          {alertTitle && (
            <AlertTitle mt={4} mb={1} fontSize="lg">
              {alertTitle}
            </AlertTitle>
          )}
        </Alert>
      </div>
    </div>
  );
};

export default AlertMessage;
