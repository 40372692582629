import { Button, Center, Flex, Heading } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";

const Unauthorized = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  return (
    <Flex
      bg="gray.100"
      minH="100vh"
      display={"flex"}
      w={"100%"}
      justifyContent={"center"}
    >
      <Center flexDir={"column"}>
        <Heading size="lg">{t("errors.unauthorized")}</Heading>
        <Button
          colorScheme="teal"
          mt={5}
          onClick={() => navigate(user ? "/jobs" : "/")}
        >
          {t("errors.backToHomePage")}
        </Button>
      </Center>
    </Flex>
  );
};

export default Unauthorized;
