import {
  TableContainer,
  Table as ChakraTable,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Avatar,
  Flex,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import { JobsTableRows } from "../types/job";
import { useTranslation } from "react-i18next";
import { formatDate, isDateString } from "../lib/utils";
import { jobStatusMapper, userTypeMapper } from "../lib/mappers";
import { UsersTableRows } from "../types/user";
import { RiAlertFill } from "react-icons/ri";

interface TableProps {
  rows: JobsTableRows[] | UsersTableRows[];
  type: "user" | "job";
  onRowClick: (rowId: number) => void;
}

const Table = ({ rows, type, onRowClick }: TableProps) => {
  const { t } = useTranslation();
  const rowHeadings = rows.length
    ? Object.keys(rows[0]).map((rowHeading) => {
        return t(`${type}.${rowHeading}`);
      })
    : [];

  return (
    <TableContainer>
      <ChakraTable
        variant="striped"
        colorScheme="teal"
        size={"lg"}
        color={"gray.300"}
      >
        <Thead>
          <Tr>
            {rowHeadings.map((rowHeading) => {
              if (
                rowHeading === "id" ||
                rowHeading === "ID" ||
                rowHeading === t(`job.notification`)
              )
                return null;
              return (
                <Th key={rowHeading} whiteSpace="normal">
                  {rowHeading}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {rows.map((row: JobsTableRows | UsersTableRows) => {
            return (
              <Tr
                key={row.id}
                cursor={"pointer"}
                onClick={() => onRowClick(row.id)}
              >
                {Object.keys(row).map((heading) => {
                  if (heading === "status" && type === "job") {
                    return (
                      <Td key={heading} whiteSpace="normal">
                        {jobStatusMapper(Number(row[heading]), t)}
                      </Td>
                    );
                  }
                  if (heading === "type" && type === "user") {
                    return (
                      <Td key={heading} whiteSpace="normal">
                        {userTypeMapper(Number(row[heading]), t)}
                      </Td>
                    );
                  }
                  if (heading === "filePath" && type === "user") {
                    return (
                      <Td key={heading} whiteSpace="normal">
                        <Avatar src={`${row[heading]}`} />
                      </Td>
                    );
                  }
                  if (heading === "updatedAt" && type === "job") {
                    return (
                      <Td
                        key={heading}
                        position={"relative"}
                        whiteSpace="normal"
                      >
                        {row[heading] && isDateString(row[heading].toString())
                          ? formatDate(row[heading].toString())
                          : row[heading]}
                        {row.notification && (
                          <Tooltip
                            label={row.notification}
                            bg="teal.600"
                            color="white"
                            placement={"left"}
                          >
                            <Flex
                              position="absolute"
                              top={4}
                              right={2}
                              alignItems="center"
                              justifyContent="center"
                              bg="transparent"
                            >
                              <Icon
                                as={RiAlertFill}
                                w={"25px"}
                                h={"25px"}
                                color={"red.500"}
                              />
                            </Flex>
                          </Tooltip>
                        )}
                      </Td>
                    );
                  }
                  if (heading === "id" || heading === "notification")
                    return null;
                  return (
                    <Td
                      key={heading}
                      whiteSpace="normal"
                      w={heading === "title" ? "35%" : "unset"}
                    >
                      {row[heading] && isDateString(row[heading].toString())
                        ? formatDate(row[heading].toString())
                        : row[heading]}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  );
};

export default Table;
