import {
  Avatar,
  Divider,
  Flex,
  Heading,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { useContext } from "react";
import { FiMenu, FiLogOut } from "react-icons/fi";
import { FaUsers } from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import { BsBriefcaseFill } from "react-icons/bs";
import NavItem from "./NavItem";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { userTypeMapper } from "../lib/mappers";
import { UserType } from "../types/user";

const Sidebar = () => {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const { isSidebarOpen, toggleSidebar } = useContext(AppContext);

  const logoutUser = () => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("user");
    navigate(`/`);
  };

  return (
    <Flex
      position={"sticky"}
      mr={5}
      top={0}
      h={"100vh"}
      width={!isSidebarOpen ? "75px" : "200px"}
      bg={"white"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      borderRadius={!isSidebarOpen ? "0 15px 15px 0" : " 0 30px 30px 0"}
      boxShadow={"0 4px 12px 0 rgba(0, 0, 0, 0.05)"}
      zIndex={1}
      flexShrink={0}
    >
      <Flex
        p={"5%"}
        flexDir={"column"}
        alignItems={!isSidebarOpen ? "center" : "flex-end"}
        as="nav"
      >
        <IconButton
          background={"none"}
          mt={"5"}
          _hover={{ background: "none" }}
          icon={<FiMenu />}
          aria-label={""}
          onClick={() => toggleSidebar()}
        />
        <NavItem
          title={t("sidebar.jobs")}
          icon={BsBriefcaseFill}
          href="/jobs"
          active={window.location.pathname.includes("/jobs")}
        />
        {user?.type === UserType.Admin && (
          <NavItem
            title={t("sidebar.users")}
            icon={FaUsers}
            active={window.location.pathname.includes("/users")}
            href="/users"
          />
        )}

        <NavItem
          title={t("sidebar.myProfile")}
          icon={ImProfile}
          active={window.location.pathname.includes("/profile")}
          href="/profile"
        />
        <NavItem
          title={t("sidebar.logout")}
          icon={FiLogOut}
          active={false}
          onClick={() => logoutUser()}
        />
      </Flex>

      <Flex
        p={"5%"}
        flexDir={"column"}
        w={"100%"}
        alignItems={!isSidebarOpen ? "center" : "flex-start"}
        mb={4}
      >
        <Divider
          borderWidth={"sm"}
          borderColor={"gray.400"}
          display={!isSidebarOpen ? "none" : "flex"}
        />
        <Flex mt={4} align={"center"}>
          <Avatar size={"sm"} src={user?.filePath && `${user?.filePath}`} />
          <Flex
            flexDir={"column"}
            ml={4}
            display={!isSidebarOpen ? "none" : "flex"}
          >
            <Heading as={"h3"} size={"sm"}>
              {`${user?.firstName} ${user?.lastName}`}
            </Heading>
            <Text color={"gray.300"}>
              {user?.type && userTypeMapper(user.type, t)}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Sidebar;
