import { Avatar, Flex, Card, Heading, Text } from "@chakra-ui/react";
import { JobsTableRows } from "../types/job";
import { useTranslation } from "react-i18next";
import { userTypeMapper } from "../lib/mappers";
import { UsersTableRows } from "../types/user";

interface TableProps {
  rows: JobsTableRows[] | UsersTableRows[];
  type: "user" | "job";
  onRowClick: (rowId: number) => void;
}

const Table = ({ rows, type, onRowClick }: TableProps) => {
  const { t } = useTranslation();

  return (
    <>
      {rows.map((row) => {
        return type === "job" ? (
          <Card
            backgroundColor={"teal.100"}
            my={2}
            p={"2.5vh"}
            onClick={() => onRowClick(row.id)}
            key={row.id}
          >
            <Flex flexDir={"column"}>
              <Heading as={"h3"} size={"sm"}>
                {row.title}
              </Heading>
              <Text color={"gray.300"}>{row.cadastralMunicipality}</Text>
            </Flex>
          </Card>
        ) : (
          <Card
            backgroundColor={"teal.100"}
            my={2}
            p={"2.5vh"}
            onClick={() => onRowClick(row.id)}
            key={row.id}
          >
            <Flex mt={4} align={"center"}>
              <Avatar
                size={"sm"}
                src={row?.filePath ? `${row?.filePath}` : ""}
              />
              <Flex flexDir={"column"} ml={4}>
                <Heading as={"h3"} size={"sm"}>
                  {`${row?.firstName} ${row?.lastName}`}
                </Heading>
                <Text color={"gray.300"}>
                  {row?.type && userTypeMapper(Number(row.type), t)}
                </Text>
              </Flex>
            </Flex>
          </Card>
        );
      })}
    </>
  );
};

export default Table;
