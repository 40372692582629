import React, { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout";
import Table from "../components/Table";
import { getJobs } from "../services/job";
import { JobsTableRows } from "../types/job";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  IconButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BsPlus } from "react-icons/bs";
import Pagination from "../components/Pagination";
import { useNavigate } from "react-router-dom";
import Filters, { FiltersType } from "../components/Filters";
import { AppContext } from "../context/AppContext";
import { UserType } from "../types/user";
import TableMobile from "../components/TableMobile";

const JobsPage = () => {
  const { user } = useContext(AppContext);
  const [jobRows, setJobRows] = useState<JobsTableRows[]>([]);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [filters, setFilters] = useState<FiltersType & { page: number }>({
    page: 1,
    title: "",
    selectedUsers: "",
    status: 0,
    cadastralNumber: "",
    cadastralMunicipality: "",
    jobsWithNotification: false,
  });

  useEffect(() => {
    async function fetchJobs() {
      const data = await getJobs(filters.page, filters);
      setJobRows(data.jobs);
      setIsLastPage(data.isLastPage);
    }
    fetchJobs();
  }, [filters]);

  const handleNewJobButton = () => {
    navigate("/jobs/new");
  };

  const handleRowClick = (rowId: number) => {
    navigate(`/jobs/detail/${rowId}`);
  };

  const onPrevClick = () => {
    if (filters.page > 1)
      setFilters({
        ...filters,
        page: filters.page - 1,
      });
  };

  const onNextClick = () => {
    if (!isLastPage)
      setFilters({
        ...filters,
        page: filters.page + 1,
      });
  };

  const onFilterChange = async (filters: FiltersType) => {
    setFilters({
      ...filters,
      page: 1,
    });
  };

  return (
    <Layout>
      <Flex
        flexDir={"column"}
        m={isMobile ? 0 : "2.5vh"}
        p={isMobile ? "2.5vh" : 0}
        w={"100%"}
        h={"100%"}
      >
        <Flex
          w={"100%"}
          flexDir={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          my={5}
        >
          <Heading size="lg" color={"teal.600"}>
            {t("sidebar.jobs")}
          </Heading>
          {user?.type === UserType.Admin && (
            <ButtonGroup size="md" isAttached colorScheme="teal">
              <Button onClick={handleNewJobButton}>{t("job.newJob")}</Button>
              <IconButton
                aria-label="Add new job"
                icon={<BsPlus />}
                onClick={handleNewJobButton}
              />
            </ButtonGroup>
          )}
        </Flex>
        <Card my={7} bgColor={"teal.100"}>
          <CardHeader pb={0}>
            <Heading size="md" color={"gray.600"} fontSize={"16px"}>
              {t("filters.title").toUpperCase()}
            </Heading>
          </CardHeader>
          <CardBody py={1}>
            <Filters onChange={onFilterChange} />
          </CardBody>
        </Card>
        {isMobile ? (
          <TableMobile rows={jobRows} type="job" onRowClick={handleRowClick} />
        ) : (
          <Table rows={jobRows} type="job" onRowClick={handleRowClick} />
        )}
        {!!jobRows.length ? (
          <Pagination
            isLastPage={isLastPage}
            isFirstPage={filters.page === 1}
            onPrevClick={onPrevClick}
            onNextClick={onNextClick}
          />
        ) : (
          <Heading
            size="sm"
            mb={"2.5vh"}
            mt={isMobile ? 5 : 0}
            color={"teal.600"}
            sx={{
              textAlign: "center",
            }}
          >
            {t("job.noJobsFound")}
          </Heading>
        )}
      </Flex>
    </Layout>
  );
};

export default JobsPage;
