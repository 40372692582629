import { createContext, useState, useEffect } from "react";
import { User } from "../types/user";
import { AlertStatus } from "../types/ServerResponse";

interface IAppContext {
  user?: User;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  setUser: (user: User) => void;
  showAlertMessage: boolean;
  alertStatus: AlertStatus;
  setAlertMessage: (title?: string, status?: AlertStatus) => void;
  closeAlertMessage: () => void;
  alertTitle: string;
}

export const AppContext = createContext<IAppContext>({
  user: undefined,
  isSidebarOpen: false,
  toggleSidebar: () => {},
  setUser: (user: User) => {},
  showAlertMessage: false,
  setAlertMessage: (title?: string, status?: AlertStatus) => {},
  closeAlertMessage: () => {},
  alertTitle: "",
  alertStatus: AlertStatus.Error,
});

export const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | undefined>(() => {
    const userFromStorage = localStorage.getItem("user");
    return userFromStorage ? JSON.parse(userFromStorage) : undefined;
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [showAlertMessage, setShowAlertMessage] = useState<boolean>(false);
  const [alertTitle, setAlertTitle] = useState<string>("");
  const [alertStatus, setAlertStatus] = useState<AlertStatus>(
    AlertStatus.Error
  );

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const closeAlertMessage = () => {
    setShowAlertMessage(false);
  };
  const setAlertMessage = (title?: string, status?: AlertStatus) => {
    setAlertTitle(title || "");
    setAlertStatus(status || AlertStatus.Error);
    setShowAlertMessage(true);
  };

  const updateUser = (newUser: User) => {
    setUser(newUser);
    localStorage.setItem("user", JSON.stringify(newUser));
  };

  useEffect(() => {
    const userFromStorage = localStorage.getItem("user");
    if (userFromStorage) {
      setUser(JSON.parse(userFromStorage));
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        user,
        isSidebarOpen,
        toggleSidebar,
        setUser: updateUser,
        showAlertMessage,
        setAlertMessage,
        closeAlertMessage,
        alertTitle,
        alertStatus,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
