import { format } from "date-fns";
import hrLocale from "date-fns/locale/hr";
import { JobFile } from "../types/job";

const JOB_STATUS_OPTIONS_LIST = [1, 2, 3, 4, 5, 7, 8, 9, 6];

export const getJobStatusOptionsList = (withAll?: boolean): number[] => {
  return withAll ? [0, ...JOB_STATUS_OPTIONS_LIST] : JOB_STATUS_OPTIONS_LIST;
};

const allowedExtensions = [
  "dwg",
  "tfw",
  "dxf",
  "crd",
  "jpg",
  "jpeg",
  "png",
  "gif",
  "pdf",
  "txt",
  "csv",
  "docx",
  "rtf",
  "xls",
  "xlsx",
  "txt",
  "zip",
  "rar",
  "rfw",
  "prj",
];

export const isValidPassword = (password: string): boolean => {
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  const isPasswordValid = passwordRegex.test(password);

  return isPasswordValid;
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const formattedDate = format(date, "dd.MM.yyyy HH:mm", {
    locale: hrLocale,
  });
  return formattedDate;
};

export const isDateString = (str: string): boolean => {
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
  return regex.test(str) && !isNaN(Date.parse(str));
};

export const filterImages = (fileArray: JobFile[]) => {
  return fileArray.filter((file) => {
    const fileType = file.type?.toLowerCase();
    return (
      fileType?.includes("jpg") ||
      fileType?.includes("jpeg") ||
      fileType?.includes("png")
    );
  });
};

export const areValidExtensions = (files: File[]): boolean => {
  const unsupportedFiles = files.filter((file) => {
    const ext = allowedExtensions.filter((extension) => {
      const fileNameParts = file.name.split(".");
      const fileExtensionByName =
        fileNameParts[fileNameParts.length - 1].toLowerCase();

      return (
        file.type.includes(extension) || extension.includes(fileExtensionByName)
      );
    });
    return !ext.length;
  });
  return !unsupportedFiles.length;
};

export const MAX_UPLOAD_SIZE = 52428800;

export const isValidUploadSize = (selectedFiles: File[]): boolean => {
  const totalSize = selectedFiles.reduce((accumulator, file) => {
    return accumulator + file.size;
  }, 0);

  return totalSize < MAX_UPLOAD_SIZE;
};
