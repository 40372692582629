import {
  Avatar,
  Divider,
  Flex,
  Heading,
  IconButton,
  VStack,
  useBreakpointValue,
  Text,
  useOutsideClick,
} from "@chakra-ui/react";
import { FiLogOut, FiMenu } from "react-icons/fi";
import { FaUsers } from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import { BsBriefcaseFill } from "react-icons/bs";
import NavItem from "./NavItem";
import { AppContext } from "../context/AppContext";
import { useContext, useRef } from "react";
import { UserType } from "../types/user";
import { useTranslation } from "react-i18next";
import { userTypeMapper } from "../lib/mappers";
import { useNavigate } from "react-router-dom";

const SidebarMobile = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { user } = useContext(AppContext);
  const { t } = useTranslation();
  const { isSidebarOpen, toggleSidebar } = useContext(AppContext);
  const navigate = useNavigate();

  function logoutUser(): void {
    localStorage.removeItem("jwt");
    localStorage.removeItem("user");
    navigate(`/`);
  }

  const wrapperRef = useRef(null);

  useOutsideClick({
    ref: wrapperRef,
    handler: () => {
      if (isSidebarOpen) {
        toggleSidebar();
      }
    },
  });

  return (
    <div ref={wrapperRef}>
      {isMobile && (
        <IconButton
          position="fixed"
          top={4}
          left={4}
          bg="white"
          boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
          icon={<FiMenu />}
          aria-label=""
          onClick={toggleSidebar}
          zIndex={3}
        />
      )}
      {isSidebarOpen && isMobile && (
        <VStack
          position="fixed"
          top={0}
          left={0}
          h="100vh"
          width="200px"
          bg="white"
          borderRadius="0 30px 30px 0"
          boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
          zIndex={2}
          p={5}
          pt={10}
          spacing={4}
          alignContent={"flex-end"}
        >
          <NavItem
            title={t("sidebar.jobs")}
            icon={BsBriefcaseFill}
            href="/jobs"
            active={window.location.pathname.includes("/jobs")}
            onClick={() => toggleSidebar()}
          />
          {user?.type === UserType.Admin && (
            <NavItem
              title={t("sidebar.users")}
              icon={FaUsers}
              active={window.location.pathname.includes("/users")}
              href="/users"
              onClick={() => toggleSidebar()}
            />
          )}

          <NavItem
            title={t("sidebar.myProfile")}
            icon={ImProfile}
            active={window.location.pathname.includes("/profile")}
            href="/profile"
            onClick={() => toggleSidebar()}
          />
          <NavItem
            title={t("sidebar.logout")}
            icon={FiLogOut}
            active={false}
            onClick={() => logoutUser()}
          />
          <Flex
            p={"5%"}
            flexDir={"column"}
            w={"100%"}
            alignItems={!isSidebarOpen ? "center" : "flex-start"}
            mb={4}
          >
            <Divider
              borderWidth={"sm"}
              borderColor={"gray.400"}
              display={!isSidebarOpen ? "none" : "flex"}
            />
            <Flex mt={4} align={"center"}>
              <Avatar size={"sm"} src={user?.filePath && `${user?.filePath}`} />
              <Flex
                flexDir={"column"}
                ml={4}
                display={!isSidebarOpen ? "none" : "flex"}
              >
                <Heading as={"h3"} size={"sm"}>
                  {`${user?.firstName} ${user?.lastName}`}
                </Heading>
                <Text color={"gray.300"}>
                  {user?.type && userTypeMapper(user.type, t)}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </VStack>
      )}
    </div>
  );
};

export default SidebarMobile;
