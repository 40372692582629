export type User = {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  type: number;
  createdAt?: Date;
  updatedAt?: Date;
  isActive?: boolean;
  filePath?: string;
};

export enum UserType {
  Admin = 1,
  OfficeWorker,
  FieldWorker,
}

export type UsersTableRows = {
  [key: string | number]: string | number;
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  type: number;
};
