import React from "react";
import LoginForm from "../components/LoginForm";
import { Center } from "@chakra-ui/react";

const LoginPage = () => {
  return (
    <Center w={"100%"} minH={"100vh"} backgroundColor={"gray.200"}>
      <LoginForm />
    </Center>
  );
};

export default LoginPage;
